import {Injectable, signal} from '@angular/core';
import {ApiService} from "@core/services/api.service";
import {
  AuthenticationInitialChangePasswordRequest,
  AuthenticationRequest,
  AuthenticationResponse
} from "@core/models/authentication.model";
import {firstValueFrom} from "rxjs";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {AuthenticationEndPoint} from "@core/const";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  shouldChangePasswordSignal = signal<boolean>(false);

  constructor(private apiService: ApiService,
              private cookieService: CookieService,
              private router: Router) {
  }

  async login(request: AuthenticationRequest): Promise<void> {
    const response = await firstValueFrom(this.apiService.post<AuthenticationRequest, AuthenticationResponse>(AuthenticationEndPoint.LOGIN, request));
    if (!response.hasChangedPassword) {
      this.shouldChangePasswordSignal.set(true);
      return;
    }
    await this.router.navigate(["/home"]);
  }

  async changePassword(request: AuthenticationInitialChangePasswordRequest): Promise<void> {
    await firstValueFrom(this.apiService.post<AuthenticationInitialChangePasswordRequest, void>(AuthenticationEndPoint.CHANGE_PASSWORD, request));
    await this.router.navigate(["/home"]);
    this.shouldChangePasswordSignal.set(false);
  }

  async refresh() {
    await firstValueFrom(this.apiService.get<void>(AuthenticationEndPoint.REFRESH, {}, "body", false));
  }

  async logout() {
    await firstValueFrom(this.apiService.get<void>(AuthenticationEndPoint.LOGOUT));
    await this.router.navigate(["/login"]);
  }

  isAccessTokenValid(): boolean {
    return this.cookieService.check("accessToken");
  }
}
